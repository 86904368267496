<template>
  <div>
    <h2 class="text-center">合同执行额分布</h2>
    <Row class="p-b-10 p-t-5">
      <i-col span="16" class="title">合同执行额（时间段内合计）</i-col>
      <i-col span="8" class="text-right">{{formatMoney(pagedata_contractExecuteAmount)}}</i-col>
    </Row>

    <div style="width:100%;height:250px;" id="workMonthReport"></div>

    <h2 class="text-center p-b-10">站点执行额排名</h2>
    <Row  class="table-title text-right" :gutter="8">
      <i-col span="5" class="text-left">站点</i-col>
      <i-col span="5">发布费(元)</i-col>
      <i-col span="5">服务费(元)</i-col>
      <i-col span="5">执行额(元)</i-col>
      <i-col span="4">占比</i-col>
    </Row>
    <Row v-for="(item,index) in stationExecuteData" :key="index" :gutter="8" class="p-t-10 text-right">
      <i-col span="5" class="text-left">{{item.name}}</i-col>
      <i-col span="5">{{formatMoneyNoPrefix(item.usePrice)}}</i-col>
      <i-col span="5">{{formatMoneyNoPrefix(item.servicePrice)}}</i-col>
      <i-col span="5">{{formatMoneyNoPrefix(item.value)}}</i-col>
      <i-col span="4">{{item.rate}} %</i-col>
    </Row>
    <p v-if="stationExecuteData.length===0" class="remark p-t-20 text-center">暂无数据</p>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { toMoney, toMoneyNoPrefix, toNumber } from '@/utils/wnumb_own'
import { countExecuteAmountByPackage } from '@/api/dw/publisher'

export default {
  data () {
    return {
      pagedata_contractExecuteAmount: 0,

      stationExecuteData: [],
      dataSourcePie: null
    }
  },
  mounted () {
    this.loadExecuteAmountGb()
    this.loadStationData()
  },
  methods: {
    loadExecuteAmountGb () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        startDate: this.searchSchedule.startDate,
        gbType: 'month',
        packageIds: this.gbId
      }

      // 获取合同执行额
      countExecuteAmountByPackage(queryModel).then(res => {
        let totalMoney = 0
        const xData = []
        const yData = []
        res.forEach(element => {
          totalMoney += element.value
          xData.push(element.name)
          yData.push(element.value)
        })

        this.pagedata_contractExecuteAmount = totalMoney
        this.initReport(xData, yData)
      })
    },
    loadStationData () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        startDate: this.searchSchedule.startDate,
        gbType: 'station',
        packageIds: this.gbId
      }

      // 执行额排名
      countExecuteAmountByPackage(queryModel).then(res => {
        this.stationExecuteData = res
      })
    },
    initReport (xData, yData) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        this.dataSourcePie = echarts.init(document.getElementById('workMonthReport'))

        const option = {
          color: ['#2b85e4'],
          xAxis: {
            type: 'category',
            data: xData,
            axisLabel: {
              color: '#c5c8ce'
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              show: false
            },
            splitLine: {
              lineStyle: {
                opacity: 0.1
              }
            }
          },
          grid: {
            top: '10%',
            left: '5%',
            right: '5%',
            bottom: '20%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999'
              }
            }
          },
          series: [
            {
              data: yData,
              type: 'bar'
            }
          ]
        }

        this.dataSourcePie.setOption(option, true)
        const that = this
        window.addEventListener('resize', function () {
          that.dataSourcePie.resize()
        })
      })
    },
    formatMoney (money) {
      return toMoney(money)
    },
    formatMoneyNoPrefix (money) {
      return toMoneyNoPrefix(money)
    },
    formatNumber (number) {
      return toNumber(number)
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    gbId () {
      return this.$store.state.situationWeb.gbId
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadStationData()
        this.loadExecuteAmountGb()
      }
    },
    gbId () {
      this.loadStationData()
      this.loadExecuteAmountGb()
    }
  }
}
</script>
